/**
 * JavaScript doesn't natively support enums, so we need to use something with similar logic.
 * Object.freeze prevents the object and it's properties from being modified.
 * Symbol ensures, that each property has a unique value.
 */

export const ReportingActions = Object.freeze({
  ProductSync: Symbol("productSync"),
  PriceSync: Symbol("pricesSync"),
  StockSync: Symbol("stockSync"),
  CategorySync: Symbol("categorySync")
});
