import { computed } from "vue";
import i18n from "@/core/plugins/vue-i18n";
import { useFeatures } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useFeatures";

const { features } = useFeatures();

const featureActions = {
  product: {
    key: "syncProduct",
    icon: "fa-kit fa-light-cube-rotate",
    emit: "sync-product",
    tooltip: "salesChannelManagementApp.reporting.productSync"
  },
  stock: {
    key: "syncStock",
    icon: "fa-kit fa-light-cubes-rotate",
    emit: "sync-stock",
    tooltip: "salesChannelManagementApp.reporting.stockSync"
  },
  prices: {
    key: "syncPrice",
    icon: "fa-kit fa-light-tag-rotate",
    emit: "sync-price",
    tooltip: "salesChannelManagementApp.reporting.pricesSync"
  }
};
const batchFeatureActions = {
  product: {
    key: "syncProducts",
    icon: "fa-kit fa-light-cube-rotate",
    emit: "sync-products",
    tooltip: "salesChannelManagementApp.reporting.syncProducts"
  },
  stock: {
    key: "syncStocks",
    icon: "fa-kit fa-light-cubes-rotate",
    emit: "sync-stocks",
    tooltip: "salesChannelManagementApp.reporting.syncStocks"
  },
  prices: {
    key: "syncPrices",
    icon: "fa-kit fa-light-tag-rotate",
    emit: "sync-prices",
    tooltip: "salesChannelManagementApp.reporting.syncPrices"
  }
};
const featureTableFields = {
  product: {
    key: "baseDataLIC",
    label: i18n.t("table.baseData"),
    type: "statusDatetime",
    dateTimeStart: "baseDataLIS",
    errorKey: "baseDataLE",
    sortable: true
  },
  stock: {
    key: "stockLIC",
    label: i18n.t("table.stock"),
    type: "statusDatetime",
    dateTimeStart: "stockLIS",
    errorKey: "stockLE",
    sortable: true
  },
  prices: {
    key: "priceLIC",
    label: i18n.t("table.price"),
    type: "statusDatetime",
    dateTimeStart: "priceLIS",
    errorKey: "priceLE",
    sortable: true
  },
  offer: {
    key: "priceLIC",
    label: i18n.t("table.offer"),
    type: "statusDatetime",
    dateTimeStart: "priceLIS",
    errorKey: "priceLE",
    sortable: true
  }
};
export const useReporting = () => {
  const reportingTableActions = computed(() => {
    let actionList = [];

    for (const feature of Object.keys(featureActions)) {
      if (!features.value.includes(feature)) continue;
      const action = featureActions[feature];

      actionList.push({
        ...action,
        tooltip: i18n.t(action.tooltip)
      });
    }

    return actionList;
  });

  const batchReportingTableActions = computed(() => {
    let actionList = [];

    for (const feature of Object.keys(batchFeatureActions)) {
      if (!features.value.includes(feature)) continue;
      const action = batchFeatureActions[feature];

      actionList.push({
        ...action,
        tooltip: i18n.t(action.tooltip)
      });
    }

    return actionList;
  });

  const reportingTableHeaders = computed(() => {
    let headerList = [
      {
        key: "id",
        label: i18n.t("table.id"),
        sortable: true
      },
      {
        key: "productNumber",
        label: i18n.t("table.productNumber"),
        sortable: true
      },
      {
        key: "name",
        label: i18n.t("table.name"),
        sortable: true
      },
      {
        key: "isVariant",
        label: i18n.t("table.type"),
        type: "badge",
        snippets: {
          true: i18n.t("salesChannelManagementApp.reporting.isVariant")
        },
        sortable: true
      }
    ];

    for (const feature of Object.keys(featureTableFields)) {
      if (!features.value.includes(feature)) continue;
      const field = featureTableFields[feature];

      headerList.push(field);
    }

    return headerList;
  });

  return {
    reportingTableActions,
    reportingTableHeaders,
    batchReportingTableActions
  };
};
