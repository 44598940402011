import { computed, ref } from "vue";
import { useStore } from "@/core/services/store";
import ProcessManager from "@/components/Workflows/processManager";
import { Error } from "@/core/plugins/swal";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import { useUtility } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useUtility";
import { ReportingActions } from "@/components/ExternalApps/SalesChannelManagementApp/utility/enum";
import { transformParameters } from "@/components/ExternalApps/SalesChannelManagementApp/utility/reporting";

const store = useStore();
const { selectedIntegrationName } = useIntegration();
const { xentralProjectHeader } = useUtility();
const SYNC_BATCH_WORKFLOW_ID = computed(
  () => store.getters.environmentVariables.syncBatchWorkflowId
);

export const useReportingSync = () => {
  const showSyncModal = ref(false);
  const showSyncBatchModal = ref(false);
  const selectedEntryNumber = ref();
  const selectedReportingAction = ref();
  const selectedReportingBatchAction = ref();
  // Convert the description of the current reporting action to match the naming scheme of the features
  const convertedReportingActionDescription = computed(() =>
    selectedReportingAction?.value?.description.replace("Sync", "")
  );
  const syncWorkflowId = computed(
    () =>
      store.getters?.testWorkflowId(
        convertedReportingActionDescription.value
      ) ?? ""
  );

  /**
   * @param {Symbol} action
   */
  function onOpenBatchSync(action) {
    selectedReportingBatchAction.value = action;
    showSyncBatchModal.value = true;
  }

  /**
   * @param {Symbol} action
   * @param {Object} entry
   */
  function onOpenSync(action, entry) {
    const { item = {} } = entry;
    selectedEntryNumber.value = item?.id ?? "";
    selectedReportingAction.value = action;
    showSyncModal.value = true;
  }

  async function executeSync() {
    showSyncModal.value = false;

    addEventToLoadingQueue({ key: "execute-sync" });

    try {
      let params = {
        primary: selectedEntryNumber.value,
        channelSpecificName: selectedIntegrationName.value,
        test: true
      };

      if (selectedReportingAction.value === ReportingActions.CategorySync) {
        delete params.primary;
      }

      if (selectedReportingAction.value === ReportingActions.PriceSync) {
        params["addPricesToProduct"] = true;
      }

      const response = await ProcessManager.run(
        syncWorkflowId.value,
        {
          params: params,
          dispatchImmediately:
            selectedReportingAction.value !== ReportingActions.CategorySync
        },
        xentralProjectHeader.value
      );

      if (response?.data?.status === "process.error") {
        Error(response.data.content_identifier);
        removeEventFromLoadingQueue({ key: "execute-sync" });
        return;
      }

      removeEventFromLoadingQueue({
        key: "execute-sync",
        type: "success",
        prefix: "salesChannelManagementApp",
        name:
          "reporting." +
          selectedReportingAction?.value?.description +
          "Finished"
      });
    } catch (error) {
      Error(error);
    } finally {
      removeEventFromLoadingQueue({ key: "execute-sync" });
    }
  }

  async function executeBatchSync(
    allRowsSelected,
    selectedRows,
    parameters,
    isFiltered = false
  ) {
    showSyncBatchModal.value = false;
    addEventToLoadingQueue({ key: "execute-batch-sync" });

    let params = {
      product:
        selectedReportingBatchAction.value === ReportingActions.ProductSync,
      stock: selectedReportingBatchAction.value === ReportingActions.StockSync,
      price: selectedReportingBatchAction.value === ReportingActions.PriceSync
    };

    if (!allRowsSelected && selectedRows?.length > 0) {
      params.productIds = selectedRows;
    }

    if (allRowsSelected) {
      params.parameters = transformParameters(parameters);
    }

    params.isFiltered = isFiltered;

    removeEventFromLoadingQueue({ key: "execute-batch-sync" });

    try {
      const response = await ProcessManager.run(SYNC_BATCH_WORKFLOW_ID.value, {
        params: params
      });

      if (response?.data?.status === "process.error") {
        Error(response.data.content_identifier);
        removeEventFromLoadingQueue({ key: "execute-batch-sync" });
        return;
      }

      removeEventFromLoadingQueue({
        key: "execute-batch-sync",
        type: "success",
        prefix: "salesChannelManagementApp",
        name:
          "reporting." +
          selectedReportingBatchAction?.value?.description +
          "Finished"
      });
    } catch (error) {
      Error(error);
    } finally {
      removeEventFromLoadingQueue({ key: "execute-batch-sync" });
    }
  }

  return {
    selectedReportingAction,
    selectedReportingBatchAction,
    showSyncModal,
    showSyncBatchModal,
    syncWorkflowId,
    onOpenSync,
    executeBatchSync,
    executeSync,
    onOpenBatchSync
  };
};
